import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FaRegCommentAlt, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

import Carousel from './carousel/carousel';
import UnderlineHeading from './underline';

type ITestimonial = {
  node: {
    author: string;
    text: string;
  };
};

function Testimonial({ testimonial }: { testimonial: ITestimonial }) {
  const { text, author } = testimonial.node;

  return (
    <div className="carousel-child max-w-3xl text-white py-4 px-2 md:px-4 md:w-2/3 xl:w-1/2 w-10/12 drawn-border h-96 md:h-60">
      <div className="flex-center flex-col h-full px-1 md:px-2">
        <div style={{ height: '90%' }} className="text-left py-4 px-1 md:px-2 overflow-y-scroll">
          <FaQuoteLeft className="text-xl" />
          <p className="pt-1 text-center mx-auto px-1 md:px-2 md:leading-7">{`${text}`}</p>
          <FaQuoteRight className="ml-auto text-xl" />
        </div>
        <h4 className="italic font-semibold text-lg">{author}</h4>
      </div>
    </div>
  );
}

function Testimonials() {
  const data = useStaticQuery(graphql`
    query {
      allTestimonialsJson {
        edges {
          node {
            author
            text
          }
        }
      }
    }
  `);

  const testimonials: ITestimonial[] = data.allTestimonialsJson.edges;

  return (
    <div className="text-center px-2 pb-8">
      <div className="inline-block">
        <UnderlineHeading marginBottom="mb-8">
          <h3 className="inline-block text-3xl font-bold">
            Testimonials <FaRegCommentAlt className="inline-block text-contrast" />
          </h3>
        </UnderlineHeading>
      </div>
      <div className="w-full">
        <Carousel>
          {testimonials.map(t => (
            <Testimonial key={`${t.node.author}`} testimonial={t} />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Testimonials;
