import React, { useState, useEffect, ReactNode } from 'react';
import { graphql } from 'gatsby';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { FaChild } from 'react-icons/fa';
import { MdSchool } from 'react-icons/md';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Testimonials from '../components/testimonials';

function PercentageChart({
  number,
  animationDuration,
  inView,
}: {
  number: number;
  animationDuration: number;
  inView: boolean;
}) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (inView) {
      setPercentage(number);
    }
  }, [inView]);

  const radius = 65;
  const circumference = 2 * Math.PI * radius;
  const strokePercentage = ((100 - percentage) * circumference) / 100;

  return (
    <svg className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" width="200" height="200">
      <g transform="rotate(-90 100 100)">
        <circle
          style={{
            transition: `all ${animationDuration}s ease-in-out`,
          }}
          r={radius}
          cx="100"
          cy="100"
          fill="transparent"
          stroke="#d36135"
          strokeWidth="0.75rem"
          strokeDasharray={circumference}
          strokeDashoffset={strokePercentage}
        />
      </g>
    </svg>
  );
}

function StatBox({
  children,
  animationDuration,
  number,
  label,
  percentage,
  suffix,
}: {
  children: ReactNode;
  animationDuration: number;
  number: number;
  label: string;
  percentage: boolean;
  suffix: string;
}) {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.75 });

  return (
    <div ref={ref} className="w-full md:w-1/3 h-60 p-2">
      <div className="drawn-border h-full w-full flex flex-col items-center">
        <div style={{ height: '80%' }} className="flex flex-col items-center justify-center relative w-full">
          {percentage && <PercentageChart number={number} animationDuration={animationDuration} inView={inView} />}
          <CountUp
            className="text-4xl font-bold"
            start={0}
            end={inView ? number : 0}
            delay={1}
            duration={animationDuration}
            suffix={suffix}
          />
          {!percentage && <div className="text-5xl mt-4 md:absolute md:bottom-2">{children}</div>}
        </div>
        <div style={{ height: '20%' }}>
          <span className="text-2xl">{label}</span>
        </div>
      </div>
    </div>
  );
}

function IndexPage({
  data,
}: {
  data: {
    pagesJson: {
      sections: string[];
    };
  };
}) {
  const { sections } = data.pagesJson;
  const animationDuration = 3;

  return (
    <Layout page="home">
      <SEO title="Home" />
      <div className="pt-16 pb-8 md:py-12 px-2 text-center max-w-screen-lg mx-auto flow">
        {sections.map((s, i) => (
          <p key={s} className={`${i === 0 ? 'text-xl md:text-3xl font-bold' : 'text-lg'}`}>
            {s}
          </p>
        ))}
      </div>
      <div className="pb-8 flex items-center justify-around flex-col md:flex-row">
        <StatBox animationDuration={animationDuration} number={500} suffix="+" label="Students">
          <FaChild className="text-contrast" />
        </StatBox>
        <StatBox animationDuration={animationDuration} number={96} label="Pass Rate" suffix="%" percentage />
        <StatBox animationDuration={animationDuration} number={20} suffix="+" label="Years Teaching">
          <MdSchool className="text-contrast" />
        </StatBox>
      </div>
      <Testimonials />
    </Layout>
  );
}
export default IndexPage;

export const query = graphql`
  {
    pagesJson(page: { eq: "index" }) {
      sections
    }
  }
`;
